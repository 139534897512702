import React from 'react'

export const StyledButton = ({ children }) => {
  return (
    <div className="font-headline tracking-wide relative pb-2 group pr-2">
      <span>{children}</span>
      <span className="font-medium transition-all duration-200 absolute bottom-0 left-0 right-2 group-hover:right-0 border-b-2 border-brand"></span>
    </div>
  )
}
