import React from 'react'

import { Link } from 'gatsby';

import { useSiteConfig } from '../../GraphQl/useSiteConfig';

import { LogoFooter } from '../ui';

import { NavLink } from './navLink';

import { PortableTextBlock } from '../sanity/portableTextBlock';

export const Footer = () => {  

  const { footerNav, addresses } = useSiteConfig();

  return (
    <footer className="bg-tint px-gutter leading-relaxed pt-12">
      <div className="grid gap-x-4 gap-y-8 grid-cols-2 lg:grid-cols-5 grid-flow-row">
        <LogoFooter className="col-span-2 lg:col-span-1" />
        {addresses.map((item) => {
          const { heading, text } = item;
          return (
            <div className="col-span-1">
              <p className="text-brand font-display text-lg mb-6">{heading && heading}</p>
              {text && <PortableTextBlock text={text} />}
            </div>
          );
        })}
      </div>
      <div className="w-full border-t border-brand py-12 mt-16 text-gray text-center text-sm flex flex-col md:flex-row justify-between">
        <span>© Infrawork 2021. All rights reserved.</span>
        {footerNav.map((item) => (
          <NavLink className="border-b border-transparent" {...item} />
        ))}
      </div>
    </footer>
  );
}
