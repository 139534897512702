import React from 'react'

export const LogoFooter = ({ className }) => {
  return (
    <svg
      className={`${className}`}
      width="93"
      height="29"
      viewBox="0 0 93 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M78.5164 28.9672C86.5155 28.9672 93 22.4827 93 14.4836C93 6.48453 86.5155 0 78.5164 0C70.5173 0 64.0328 6.48453 64.0328 14.4836C64.0328 22.4827 70.5173 28.9672 78.5164 28.9672Z"
        fill="#008E84"
      />
      <path
        d="M58.6967 28.9672C66.6958 28.9672 73.1803 22.4827 73.1803 14.4836C73.1803 6.48453 66.6958 0 58.6967 0C50.6977 0 44.2131 6.48453 44.2131 14.4836C44.2131 22.4827 50.6977 28.9672 58.6967 28.9672Z"
        fill="#CF2630"
      />
      <path
        opacity="0.65"
        d="M64.0328 14.4836C64.0328 18.4326 65.7677 21.9346 68.6065 24.3934C71.3666 21.9346 73.1803 18.3581 73.1803 14.4836C73.1803 10.609 71.4454 7.03256 68.6065 4.57373C65.8465 7.03256 64.0328 10.609 64.0328 14.4836Z"
        fill="#008E84"
      />
      <path
        d="M14.4341 28.9672C22.4059 28.9672 28.8683 22.4827 28.8683 14.4836C28.8683 6.48453 22.4059 0 14.4341 0C6.46239 0 0 6.48453 0 14.4836C0 22.4827 6.46239 28.9672 14.4341 28.9672Z"
        fill="#2E3BA7"
      />
    </svg>
  )
};

