import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Headroom from 'react-headroom';
import { Link } from 'gatsby';
import { useSiteConfig } from '../../GraphQl/useSiteConfig';
import { NavLink } from './navLink';
import { MenuToggle } from './menuToggle';
import { MobileNav } from './MobileNav';

// Hooks, Querys & State
import { useAppState } from '../../state/appState';

import LogoImage from '../../images/logo.png';
import ReversedLogoImage from '../../images/reversedLogo.png';

// Animation Variants
const headerVars = {
  hidden: {
    opacity: 0,
    transition: {
      duration: 0.5,
      delay: 0.4,
    },
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
      delay: 0.5,
    },
  },
};

export const Header = () => {
  const [headerPinned, setHeaderPinned] = useState(false);

  const { mainNav } = useSiteConfig();

  const { headerLight, navOpen, toggleNav } = useAppState();

  return (
    <>
      <AnimatePresence>
        <Headroom
          style={{ zIndex: '2500' }}
          onPin={() => setHeaderPinned(true)}
          onUnpin={() => setHeaderPinned(false)}
          onUnfix={() => setHeaderPinned(false)}
        >
          <motion.header
            variants={headerVars}
            initial='hidden'
            animate='visible'
            exit='hidden'
            id='header'
            className={`font-body tracking-global transition-all duration-200 flex justify-between items-center w-full px-4 md:px-gutter xl:px-20 py-4 md:py-4 lg:px-8 ${
              headerLight && !headerPinned ? 'text-white' : 'text-charcoal'
            } ${headerPinned && !navOpen ? 'bg-white' : 'bg-transparent'} ${
              headerPinned ? 'lg:py-4' : 'lg:py-8'
            }`}
          >
            <Link to='/' className={`text-current ${navOpen && 'text-white'}`}>
              {headerPinned ? (
                <img
                  src={ReversedLogoImage}
                  alt='logo'
                  className={`w-48 lg:w-64 transition duration-100 `}
                />
              ) : (
                <img
                  src={LogoImage}
                  alt='logo'
                  className={`w-48 lg:w-64 transition duration-100 `}
                />
              )}
            </Link>
            <MenuToggle
              navOpen={navOpen}
              className={`lg:hidden ${navOpen && 'text-white'}`}
              onClick={toggleNav}
            />
            <nav
              id='header-nav'
              className='hidden lg:flex justify-center items-center gap-x-12'
            >
              {mainNav.map((item) => (
                <NavLink className='border-b border-transparent' {...item} />
              ))}
            </nav>
          </motion.header>
        </Headroom>
      </AnimatePresence>
      <MobileNav {...mainNav} />
    </>
  );
};
