import React from "react"

import { PageLink, PathLink, ExternalLink } from '../ui';

export const NavLink = ({ _type, ...rest }) => {
  const LinkComponent = () => {
    switch (_type) {
      case 'pageLink':
        return <PageLink {...rest} />;
      case 'pathLink':
        return <PathLink {...rest} />;
      case 'externalLink':
        return <ExternalLink {...rest} />;

      default:
        return null;
    }
  }
  return <LinkComponent />
}
