import React from 'react';
import AnchorScroll from 'react-anchor-link-smooth-scroll';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { Link } from 'gatsby';
import Image from 'gatsby-plugin-sanity-image';

// Hooks & Querys
import { useSanityPath } from '../../hooks';

export const PageLink = ({ className, page, linkText, ...rest }) => {
  const path = useSanityPath(page);
  return (
    <Link
      activeClassName='border-current border-b'
      className={className}
      to={path}
      {...rest}
    >
      {linkText}
    </Link>
  );
};

export const PathLink = ({ className, path, linkText, ...rest }) => {
  return (
    <Link
      activeClassName='border-current border-b'
      className={className}
      to={`/${path}`}
      {...rest}
    >
      {linkText}
    </Link>
  );
};

export const PageAnchorLink = ({
  className,
  anchor,
  page,
  linkText,
  ...rest
}) => {
  const path = useSanityPath(page);
  return (
    <AnchorLink className={className} to={`/${path}#${anchor}`} {...rest}>
      {linkText}
    </AnchorLink>
  );
};

export const AnchorScrollLink = ({ className, anchor, linkText, ...rest }) => {
  return (
    <AnchorScroll className={className} to={`#${anchor}`} {...rest}>
      {linkText}
    </AnchorScroll>
  );
};

export const ExternalLink = ({ className, linkText, link, ...rest }) => {
  return (
    <a className={className} href={link} target='_blank' rel='noreferrer'>
      {linkText}
    </a>
  );
};

export const ExternalButton = ({ className, linkText, link, ...rest }) => {
  return (
    <a className={className} href={link} target='_blank' rel='noreferrer'>
      {linkText}
    </a>
  );
};

export const PartnerLinks = ({
  className,
  linkText1,
  link1,
  linkImage1,
  linkText2,
  link2,
  linkImage2,
  ...rest
}) => {
  return (
    <div className='flex'>
      <div className=''>
        {linkImage1 && <Image className='h-3 object-contain' {...linkImage1} />}
        <a
          href={link1}
          target='_blank'
          rel='noreferrer'
          className='btn-branded mr-8 lg:mr-12'
        >
          {linkText1}
        </a>
      </div>
      <div>
        {linkImage2 && <Image className='h-3 object-contain' {...linkImage2} />}
        <a
          href={link2}
          target='_blank'
          rel='noreferrer'
          className='btn-branded'
        >
          {linkText2}
        </a>
      </div>
    </div>
  );
};
