import React from 'react'
import Image from "gatsby-plugin-sanity-image"

import { NavLink } from '../nav/navLink';


export const Card = ({ heading, caption, link, image, logo }) => {
  return (
    <div className="col-span-1">
      {image && (
        <div className="relative w-full aspect-w-3 aspect-h-2">
          <Image className="absolute inset-0 object-cover" {...image} />
        </div>
      )}

      <div className="">
        {logo.asset ? (
          <Image {...logo} className="h-6 w-auto mt-10 mb-4" />
        ) : (
          <h4 className="text-brand font-display text-xl lg:text-2xl mt-10 mb-4">
            {heading && heading}
          </h4>
        )}

        <p className="mb-4 text-gray">{caption && caption}</p>
        <div className="btn-branded">
          <NavLink {...link[0]} />
        </div>
      </div>
    </div>
  );
}
